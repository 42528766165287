import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/playlist/playlist.module').then(module => module.PlaylistModule),
  },
  {
    path: 'video-management',
    loadChildren: () => import('./modules/video/video.module').then(module => module.VideoModule),
  },
  {
    path: 'category-management',
    loadChildren: () => import('./modules/category/category.module').then(module => module.CategoryModule),
  },
  {
    path: 'about-management',
    loadChildren: () => import('./modules/about/about.module').then(module => module.AboutModule),
  },
  {
    path: 'perpustakaan-management',
    loadChildren: () =>
      import('./modules/perpustakaan/perpustakaan.module').then(module => module.PerpustakaanModule),
  },
  {
    path: 'referal-management',
    loadChildren: () => import('./modules/referal/referal.module').then(module => module.ReferalModule),
  },
  {
    path: '',
    loadChildren: () => import('./modules/auth/auth.module').then(module => module.AuthModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
