import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from '../../../../environtment/environment';

@Injectable({
  providedIn: 'root',
})
export class PlaylistService {
  constructor(private http: HttpClient) {
    //
  }

  getList(e: any): Observable<any[]> {
    const query = `?search=${e?.query?.search ?? ''}&page=${e?.query.page ?? 1}&limit=${
      e?.query.limit ?? 5
    }&paginateDisable=${e?.query.paginateDisable ?? false}&sortBy[]=createdAt|DESC`;

    return this.http.get<any[]>(environment.apiBaseUrl + '/playlist-management' + query);
  }

  async create(payload: any, file: File) {
    const formData = new FormData();
    formData.append('data', JSON.stringify(payload));
    formData.append('poster', file);

    return this.http.post<any>(environment.apiBaseUrl + '/playlist-management', formData).pipe(
      map(data => {
        if (typeof window !== 'undefined') {
          return data;
        }
        return data.data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }),
    );
  }

  async update(id: string, payload: any, file) {
    const formData = new FormData();
    formData.append('data', JSON.stringify(payload));
    formData.append('poster', file);

    return this.http.put<any>(environment.apiBaseUrl + `/playlist-management/${id}`, formData).pipe(
      map(data => {
        if (typeof window !== 'undefined') {
          return data;
        }
        return data.data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }),
    );
  }

  async publish(playlist: string) {
    return this.http
      .patch<any>(environment.apiBaseUrl + '/playlist-management/publish-playlist/' + playlist, {})
      .pipe(
        map(data => {
          if (typeof window !== 'undefined') {
            return data;
          }
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  async delete(playlistId: string) {
    return this.http.delete<any>(environment.apiBaseUrl + '/playlist-management/' + playlistId, {}).pipe(
      map(data => {
        if (typeof window !== 'undefined') {
          return data;
        }
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }),
    );
  }
}
