// effects.ts
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { loadPemateri, loadPemateriSuccess } from './pemateri.action';
import { catchError, map, mergeMap, of } from 'rxjs';
import { PemateriService } from '../../modules/pemateri/pemateri-service';

@Injectable()
export class PemateriEffects {
  constructor(private actions$: Actions, private pemateriService: PemateriService) {}

  loadPemateri$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadPemateri),
      mergeMap(e =>
        this.pemateriService.getList(e).pipe(
          map(data => loadPemateriSuccess({ data })),
          catchError(error => of(/* handle error */)),
        ),
      ),
    );
  });
}
