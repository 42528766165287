import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { DatePipe } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { CategoryEffects } from '../../states/category/category.effect';
import { CategoryComponent } from './category.component';
import { CategoryRoutingModule } from './category-routing.module';
import { ModalActionCategoryComponent } from './modal-action-category/modal-action-category.component';

@NgModule({
  declarations: [CategoryComponent, ModalActionCategoryComponent],
  imports: [CategoryRoutingModule, SharedModule.forRoot(), EffectsModule.forFeature(CategoryEffects)],
  providers: [DatePipe],
})
export class CategoryModule {}
