import { createReducer, on } from '@ngrx/store';
import { initialState } from './referal.model';
import {
  loadReferal,
  loadReferalSuccess,
  loadListReferalSuccess,
  loadReferalDetail,
  loadReferalDetailSuccess,
} from './referal.action';

export const referalReducer = createReducer(
  initialState,
  on(loadReferal, function (state) {
    return { ...state, status: 'loading', error: null };
  }),
  on(loadReferalSuccess, function (state, { data }) {
    const result: any = data;

    return { ...state, data: result.data, meta: result.meta, status: 'success' };
  }),
  on(loadListReferalSuccess, function (state, { data }) {
    const result: any = data;
    return { ...state, data: result.data, status: 'success' };
  }),
  on(loadReferalDetail, function (state) {
    return { ...state, status: 'loading-detail', error: null };
  }),
  on(loadReferalDetailSuccess, function (state, { data }) {
    return { ...state, detail: data.data, status: 'success' };
  }),
);
