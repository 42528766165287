import { createAction, props } from '@ngrx/store';

export const loadCategory = createAction('[Category] Load Category', props<{ query: any }>());
export const loadCategorySuccess = createAction('[Category] Load Category Success', props<{ data: any[] }>());

export const loadListCategory = createAction('[Category] Load  List Category', props<{ query: any }>());
export const loadListCategorySuccess = createAction(
  '[Category] Load List Category Success',
  props<{ data: any[] }>(),
);
