import { createAction, props } from '@ngrx/store';

export const loadPerpustakaan = createAction('[Perpustakaan] Load Perpustakaan', props<{ query: any }>());
export const loadPerpustakaanSuccess = createAction(
  '[Perpustakaan] Load Perpustakaan Success',
  props<{ data: any[] }>(),
);

export const loadPerpustakaanDetail = createAction(
  '[Perpustakaan] Load Perpustakaan Detail',
  props<{ perpustakaanId: string }>(),
);
export const loadPerpustakaanDetailSuccess = createAction(
  '[Perpustakaan] Load Perpustakaan Detail Success',
  props<{ data: any }>(),
);
