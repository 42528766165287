import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { authReducer } from './states/auth/auth.reducer';
import { AuthEffects } from './states/auth/auth.effects';
import { JwtInterceptorProvider } from './core/helpers/jwt.interceptor';
import { HttpErrorInterceptorProvider } from './core/helpers/errorInterceptor';
import { playlistReducer } from './states/playlist/playlist.reducer';
import { videoReducer } from './states/video/video.reducer';
import { categoryReducer } from './states/category/category.reducer';
import { pemateriReducer } from './states/pemateri/pemateri.reducer';
import { aboutReducer } from './states/about/about.reducer';
import { CategoryModule } from './modules/category/category.module';
import { PemateriModule } from './modules/pemateri/pemateri.module';
import { AboutModule } from './modules/about/about.module';
import { PlaylistModule } from './modules/playlist/playlist.module';
import { perpustakaanReducer } from './states/perpustakaan/perpustakaan.reducer';
import { referalReducer } from './states/referal/referal.reducer';

@NgModule({
  declarations: [AppComponent],
  imports: [
    EffectsModule.forRoot([AuthEffects]),
    PlaylistModule,
    PemateriModule,
    CategoryModule,
    AboutModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    SharedModule.forRoot(),
    StoreModule.forRoot({
      auth: authReducer,
      playlist: playlistReducer,
      video: videoReducer,
      category: categoryReducer,
      pemateri: pemateriReducer,
      about: aboutReducer,
      perpustakaan: perpustakaanReducer,
      referal: referalReducer,
    }),
  ],
  providers: [JwtInterceptorProvider, HttpErrorInterceptorProvider],
  bootstrap: [AppComponent],
})
export class AppModule {}
