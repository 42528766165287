export interface DataAboutInterface {
  id: string;
  name: string;
  arabic: string;
  english: string;
}

export interface AboutState {
  data: DataAboutInterface[];
  error: string | null;
  status: string;
  meta: any;
}

export const initialState: AboutState = {
  data: [],
  error: '',
  status: 'pending',
  meta: {
    totalItems: 0,
    itemCount: 0,
    itemsPerPage: 0,
    totalPages: 0,
    currentPage: 0,
  },
};
