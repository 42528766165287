import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from '../../../../environtment/environment';

@Injectable({
  providedIn: 'root',
})
export class AboutService {
  constructor(private http: HttpClient) {
    //
  }

  getList(e: any): Observable<any[]> {
    const query = `?search=${e?.query?.search ?? ''}&page=${e?.query.page ?? 1}&limit=${
      e?.query.limit ?? 10
    }&paginateDisable=${e?.query.paginateDisable ?? false}&sortBy[]=createdAt|DESC&type=${e.query.type}`;
    return this.http.get<any[]>(environment.apiBaseUrl + '/about-management' + query);
  }

  async createAbout(payload: any) {
    return this.http.post<any>(environment.apiBaseUrl + '/about-management', payload).pipe(
      map(user => {
        if (typeof window !== 'undefined') {
          return user;
        }
        return user.data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }),
    );
  }

  async update(id: string, payload: any) {
    return this.http.put<any>(environment.apiBaseUrl + '/about-management/' + id, payload).pipe(
      map(user => {
        if (typeof window !== 'undefined') {
          return user;
        }
        return user.data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }),
    );
  }

  async delete(aboutId: string) {
    return this.http.delete<any>(environment.apiBaseUrl + '/about-management/' + aboutId, {}).pipe(
      map(data => {
        if (typeof window !== 'undefined') {
          return data;
        }
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }),
    );
  }
}
