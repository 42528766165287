import { createReducer, on } from '@ngrx/store';
import { initialState } from './about.model';
import { loadAbout, loadAboutSuccess, loadListAboutSuccess } from './about.action';

export const aboutReducer = createReducer(
  initialState,
  on(loadAbout, function (state) {
    return { ...state, status: 'loading', error: null };
  }),
  on(loadAboutSuccess, function (state, { data }) {
    const result: any = data;
    return { ...state, data: result.data, meta: result.meta, status: 'success' };
  }),
  on(loadListAboutSuccess, function (state, { data }) {
    const result: any = data;
    return { ...state, data: result.data, status: 'success' };
  }),
);
