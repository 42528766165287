import { createReducer, on } from '@ngrx/store';
import { initialState } from './category.model';
import { loadCategory, loadCategorySuccess, loadListCategorySuccess } from './category.action';

export const categoryReducer = createReducer(
  initialState,
  on(loadCategory, function (state) {
    return { ...state, status: 'loading', error: null };
  }),
  on(loadCategorySuccess, function (state, { data }) {
    const result: any = data;
    return { ...state, data: result.data, meta: result.meta, status: 'success' };
  }),
  on(loadListCategorySuccess, function (state, { data }) {
    const result: any = data;
    return { ...state, data: result.data, status: 'success' };
  }),
);
