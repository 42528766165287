import { createReducer, on } from '@ngrx/store';
import { initialState } from './playlist.model';
import {
  loadPlaylist,
  loadPlaylistSuccess,
  loadPlaylistDetail,
  loadPlaylistDetailSuccess,
  loadPlaylistList,
  loadPlaylistListSuccess,
} from './playlist.action';

export const playlistReducer = createReducer(
  initialState,
  on(loadPlaylist, function (state) {
    return { ...state, status: 'loading', error: null };
  }),
  on(loadPlaylistSuccess, function (state, { data }) {
    const result: any = data;
    return { ...state, data: result.data, meta: result.meta, status: 'success' };
  }),
  on(loadPlaylistDetail, function (state) {
    return { ...state, status: 'loading-detail', error: null };
  }),
  on(loadPlaylistDetailSuccess, function (state, { data }) {
    return { ...state, detail: data.data, status: 'success' };
  }),
  on(loadPlaylistList, function (state) {
    return { ...state, error: null };
  }),
  on(loadPlaylistListSuccess, function (state, { data }) {
    const result: any = data;
    return { ...state, data: result.data };
  }),
);
