import { createReducer, on } from '@ngrx/store';
import { initialState } from './perpustakaan.model';
import {
  loadPerpustakaan,
  loadPerpustakaanDetail,
  loadPerpustakaanDetailSuccess,
  loadPerpustakaanSuccess,
} from './perpustakaan.action';

export const perpustakaanReducer = createReducer(
  initialState,
  on(loadPerpustakaan, function (state) {
    return { ...state, status: 'loading', error: null };
  }),
  on(loadPerpustakaanSuccess, function (state, { data }) {
    const result: any = data;
    return { ...state, data: result.data, meta: result.meta, status: 'success' };
  }),
  on(loadPerpustakaanDetail, function (state) {
    return { ...state, status: 'loading-detail', error: null };
  }),
  on(loadPerpustakaanDetailSuccess, function (state, { data }) {
    return { ...state, detail: data.data, status: 'success' };
  }),
);
