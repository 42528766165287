import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { AboutService } from '../about-service';
import { loadAbout } from '../../../states/about/about.action';

@Component({
  selector: 'app-modal-action-about',
  templateUrl: './modal-action-about.component.html',
  styleUrls: ['./modal-action-about.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalActionAboutComponent implements OnInit {
  @Input() isEdit = null;
  @Input() detail: any = null;
  @Input() modalCreate = false;
  @Input() limit = 10;
  @Input() search = '';
  @Input() page = 1;
  @Output() closeModal = new EventEmitter<void>();

  form: FormGroup;
  loadingModal = false;
  loadingButton = false;
  loadingDelete = false;

  errorForm = {
    name: '',
    arabic: '',
    english: '',
    type: '',
  };

  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    private messageService: MessageService,
    private aboutService: AboutService,
  ) {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      arabic: ['', [Validators.required]],
      english: ['', [Validators.required]],
      type: ['', [Validators.required]],
    });
  }

  ngOnInit() {
    if (this.isEdit) {
      this.loadingModal = true;

      this.form.patchValue({
        name: this.detail.name,
        arabic: this.detail.arabic,
        english: this.detail.english,
        type: this.detail.type,
      });

      this.loadingModal = false;
    }
  }

  closeModalCreate() {
    this.detail = null;
    this.form.patchValue({
      name: '',
      arabic: '',
      english: '',
      type: '',
    });

    this.modalCreate = false;
    this.closeModal.emit();
  }

  changeField(field) {
    this.errorForm = {
      ...this.errorForm,
      [field]: '',
    };
  }

  successAction() {
    this.store.dispatch(
      loadAbout({
        query: {
          search: '',
          page: 1,
          limit: this.limit,
          type: '',
        },
      }),
    );

    this.modalCreate = false;
    this.loadingButton = false;
    this.loadingDelete = false;

    this.messageService.add({
      severity: 'success',
      summary: 'Berhasil',
      detail: 'Berhasil menambah data',
    });
  }

  errorAction(error) {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: error?.error?.message ?? error?.message ?? error,
    });
    this.loadingButton = false;
    this.loadingDelete = false;
  }

  async onSubmit() {
    this.loadingButton = true;
    if (!this.isEdit) await this.create();
    else await this.update();
  }

  async create() {
    (await this.aboutService.createAbout(this.form.value)).subscribe(
      async () => {
        this.successAction();
      },
      error => {
        this.errorAction(error);
      },
    );
  }

  async update() {
    (await this.aboutService.update(this.detail.id, this.form.value)).subscribe(
      async () => {
        this.successAction();
      },
      error => {
        this.errorAction(error);
      },
    );
  }

  async remove() {
    this.loadingDelete = true;
    (await this.aboutService.delete(this.detail.id)).subscribe(
      async () => {
        this.successAction();
      },
      error => {
        this.errorAction(error);
      },
    );
  }
}
