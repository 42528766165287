<app-modal [showModal]="modalCreate" [title]="isEdit ? 'Edit Tentang' : 'Buat Tentang'" (closeModal)="closeModalCreate()">
  <div *ngIf="loadingModal" class="w-full flex justify-center items-center content-center">
    <h1 class="text-center">Loading...</h1>
  </div>
  <form *ngIf="!loadingModal" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="p-5">
    <div class="grid grid-cols-2 gap-5 gap-y-5">
      <div class="grid grid-cols-1">
        <label class="text-base font-[500] text-black mb-2" htmlFor="name">Nama</label>
        <input
          class="form-create"
          pInputText
          id="name"
          formControlName="name"
          placeholder="Judul"
          aria-describedby="name-help"
          (keypress)="changeField('name')"
          [ngClass]="errorForm.name !== '' ? 'ng-invalid ng-dirty': ''"
        />
        <div *ngIf="errorForm.name !== ''" class="mt-2">
          <p class="text-red-500 text-xs">{{ errorForm.name }}</p>
        </div>
      </div>
      <div class="grid grid-cols-1">
        <label class="text-base font-[500] text-black mb-2" htmlFor="arabic">Arabic</label>
        <input
          class="form-create"
          pInputText
          id="arabic"
          formControlName="arabic"
          placeholder="Judul"
          aria-describedby="arabic-help"
          (keypress)="changeField('arabic')"
          [ngClass]="errorForm.arabic !== '' ? 'ng-invalid ng-dirty': ''"
        />
        <div *ngIf="errorForm.arabic !== ''" class="mt-2">
          <p class="text-red-500 text-xs">{{ errorForm.arabic }}</p>
        </div>
      </div>
      <div class="grid grid-cols-1">
        <label class="text-base font-[500] text-black mb-2" htmlFor="english">English</label>
        <input
          class="form-create"
          pInputText
          id="english"
          formControlName="english"
          placeholder="Judul"
          aria-describedby="english-help"
          (keypress)="changeField('english')"
          [ngClass]="errorForm.english !== '' ? 'ng-invalid ng-dirty': ''"
        />
        <div *ngIf="errorForm.english !== ''" class="mt-2">
          <p class="text-red-500 text-xs">{{ errorForm.english }}</p>
        </div>
      </div>
      <div class="grid grid-cols-1">
        <label class="text-base font-[500] text-black mb-2" htmlFor="type">Tipe</label>
          <p-dropdown 
            class="create-video"
            [ngClass]="errorForm.type !== '' ? 'ng-invalid ng-dirty': ''"
            [options]="['Video', 'Perpustakaan']" 
            formControlName="type" 
            placeholder="Pilih Tipe About" 
            [filter]="true"
            appendTo="body"
            class="invite">
          </p-dropdown>
          <div *ngIf="errorForm.type !== ''" class="mt-2">
            <p class="text-red-500 text-xs">{{ errorForm.type }}</p>
          </div>
      </div>
    </div>
    <div class="w-full flex justify-start gap-5 mt-10">
      <button type="submit" [disabled]="loadingButton" [ngClass]="loadingButton ? 'bg-gray-500': 'bg-[#2A97AD]'" class="p-2 px-4 rounded-md text-white">
        <p class="text-base font-normal">{{ loadingButton ? 'Loading...': 'Simpan' }}</p>
      </button>
      <button type="button" (click)="remove()" *ngIf="isEdit" [disabled]="loadingDelete" [ngClass]="loadingDelete ? 'bg-gray-500': 'bg-red-500'" class="p-2 px-4 rounded-md text-white">
        <p class="text-base font-normal">{{ loadingDelete ? 'Loading...': 'Hapus' }}</p>
      </button>
    </div>
  </div>
</form>
</app-modal>