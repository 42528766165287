import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { DatePipe } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { PemateriEffects } from '../../states/pemateri/pemateri.effect';

@NgModule({
  declarations: [],
  imports: [SharedModule.forRoot(), SharedModule.forRoot(), EffectsModule.forFeature(PemateriEffects)],
  providers: [DatePipe],
})
export class PemateriModule {}
