// effects.ts
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { loadAbout, loadAboutSuccess, loadListAbout, loadListAboutSuccess } from './about.action';
import { catchError, map, mergeMap, of } from 'rxjs';
import { AboutService } from '../../modules/about/about-service';

@Injectable()
export class AboutEffects {
  constructor(private actions$: Actions, private aboutService: AboutService) {}

  loadAbout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadAbout),
      mergeMap(e =>
        this.aboutService.getList(e).pipe(
          map(data => loadAboutSuccess({ data })),
          catchError(error => of(/* handle error */)),
        ),
      ),
    );
  });

  loadListAbout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadListAbout),
      mergeMap(e =>
        this.aboutService.getList(e).pipe(
          map(data => loadListAboutSuccess({ data })),
          catchError(error => of(/* handle error */)),
        ),
      ),
    );
  });
}
