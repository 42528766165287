export interface DataPerpustakaanInterface {
  id: string;
  title: string;
  category: string;
  pemateri: string;
  aboit: string;
}

export interface PerpustakaanState {
  data: DataPerpustakaanInterface[];
  detail: DataPerpustakaanInterface | null;
  error: string | null;
  status: string;
  meta: any;
}

export const initialState: PerpustakaanState = {
  data: [],
  detail: null,
  error: '',
  status: 'pending',
  meta: {
    totalItems: 0,
    itemCount: 0,
    itemsPerPage: 0,
    totalPages: 0,
    currentPage: 0,
  },
};
