<div class="w-full h-screen flex flex-col base">
  <!-- HEADER -->
  <div class="py-1 green-linier">
    <div class="pl-5">
      <p class="text-2xl pt-4 pb-4 text-white">
        ALWAFA-TARIM
      </p>
    </div>
  </div>
  <div class="flex flex-1 content-min ">
    <app-sidebar></app-sidebar>
    <p-divider layout="vertical"></p-divider>
    <ng-content></ng-content>
  </div>
</div>
