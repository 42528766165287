export interface DataPlaylistInterface {
  id: string;
  title: string;
  titleEnglish: string;
  titleArabic: string;
  description: string;
  descriptionEnglish: string;
  descriptionArabic: string;
  createdAt: Date;
  isPublish: boolean;
}

export interface PlaylistState {
  data: DataPlaylistInterface[];
  detail: DataPlaylistInterface | null;
  error: string | null;
  status: string;
  meta: any;
}

export const initialState: PlaylistState = {
  data: [],
  detail: null,
  error: '',
  status: 'pending',
  meta: {
    totalItems: 0,
    itemCount: 0,
    itemsPerPage: 0,
    totalPages: 0,
    currentPage: 0,
  },
};
