/* eslint-disable @ngrx/no-typed-global-store */
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { loadAbout } from '../../states/about/about.action';
import { Subject, debounceTime, tap } from 'rxjs';
import { MessageService } from 'primeng/api';
import { selectAboutProperty } from '../../states/about/about.selector';
import { AboutService } from './about-service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
})
export class AboutComponent implements OnInit {
  modalView = false;
  modalCreate = false;
  loadingTable = true;

  headers = [
    {
      name: 'Tipe',
      align: 'left',
    },
    {
      name: 'Bahasa',
      align: 'center',
    },
    {
      name: 'Inggris',
      align: 'center',
    },
    {
      name: 'Arabic',
      align: 'center',
    },
  ];

  isEdit = false;
  detail: null;

  abouts = [];

  search = '';
  private inputSubject = new Subject<string>();

  totalRecords = 0;
  page = 1;
  limit = 5;

  constructor(
    private store: Store<{ abouts }>,
    private readonly aboutService: AboutService,
    private messageService: MessageService,
  ) {
    this.inputSubject.pipe(debounceTime(500)).subscribe(() => {
      this.onTypingFinished();
    });
  }

  onTypingFinished() {
    this.onSearch(this.search);
  }

  onInput(event: any) {
    this.inputSubject.next(event.target.value);
  }

  loadData(): void {
    this.store
      .select(selectAboutProperty)
      .pipe(
        tap((data: any) => {
          this.abouts = data.data;
          this.totalRecords = data.meta.totalItems;

          if (data.status !== 'loading') this.loadingTable = false;
          if (data.status === 'loading') this.loadingTable = true;
        }),
      )
      .subscribe();
  }

  ngOnInit() {
    this.loadAbout(this.search, this.page, this.limit);
    this.loadData();
  }

  loadAbout(search: string | null, page: number, limit: number) {
    this.store.dispatch(
      loadAbout({
        query: {
          paginateDisable: false,
          search,
          page,
          limit,
          type: '',
        },
      }),
    );
  }

  pageChange(page) {
    this.page = page;
    this.loadAbout('', this.page, this.limit);
    this.loadData();
  }

  onSearch(e: string) {
    this.page = 1;
    this.loadAbout(e, this.page, this.limit);
    this.loadData();
  }

  changeLimit(e) {
    this.page = 1;
    this.limit = e.value;
    this.loadAbout(e, this.page, this.limit);

    this.loadData();
  }

  togleModal(action: string, detail = null) {
    if (action === 'create') this.modalCreate = !this.modalCreate;
    if (action === 'edit') {
      this.detail = detail;
      this.isEdit = true;
      this.modalCreate = !this.modalCreate;
    }
  }

  closeModalCreate() {
    this.modalCreate = false;
    this.modalView = false;
    this.detail = null;
    this.isEdit = false;
  }
}
