// effects.ts
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { loadCategory, loadCategorySuccess, loadListCategory, loadListCategorySuccess } from './category.action';
import { catchError, map, mergeMap, of } from 'rxjs';
import { CategoryService } from '../../modules/category/category-service';

@Injectable()
export class CategoryEffects {
  constructor(private actions$: Actions, private categoryService: CategoryService) {}

  loadCategory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadCategory),
      mergeMap(e =>
        this.categoryService.getList(e).pipe(
          map(data => loadCategorySuccess({ data })),
          catchError(error => of(/* handle error */)),
        ),
      ),
    );
  });

  loadListCategory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadListCategory),
      mergeMap(e =>
        this.categoryService.getList(e).pipe(
          map(data => loadListCategorySuccess({ data })),
          catchError(error => of(/* handle error */)),
        ),
      ),
    );
  });
}
