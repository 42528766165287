import { createReducer, on } from '@ngrx/store';
import { initialState } from './video.model';
import {
  loadVideo,
  loadVideoSuccess,
  loadVideoDetail,
  loadVideoDetailSuccess,
  loadListBucket,
  loadListBucketSuccess,
  loadListBucketLow,
  loadListBucketStandard,
  loadListBucketLowSuccess,
  loadListBucketStandardSuccess,
} from './video.action';

export const videoReducer = createReducer(
  initialState,
  on(loadVideo, function (state) {
    return { ...state, status: 'loading', error: null };
  }),
  on(loadVideoSuccess, function (state, { data }) {
    const result: any = data;
    return { ...state, data: result.data, meta: result.meta, status: 'success' };
  }),
  on(loadVideoDetail, function (state) {
    return { ...state, status: 'loading-detail', error: null };
  }),
  on(loadVideoDetailSuccess, function (state, { data }) {
    return { ...state, detail: data.data, status: 'success' };
  }),
  on(loadListBucket, function (state) {
    return { ...state, status: 'loading-detail', error: null };
  }),
  on(loadListBucketSuccess, function (state, { data }) {
    return { ...state, buckets: data.data, status: 'success' };
  }),
  on(loadListBucketLow, function (state) {
    return { ...state, status: 'loading-detail', error: null };
  }),
  on(loadListBucketLowSuccess, function (state, { data }) {
    return { ...state, bucketsLow: data.data, status: 'success' };
  }),
  on(loadListBucketStandard, function (state) {
    return { ...state, status: 'loading-detail', error: null };
  }),
  on(loadListBucketStandardSuccess, function (state, { data }) {
    return { ...state, bucketsStandard: data.data, status: 'success' };
  }),
);
