// effects.ts
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PlaylistService } from 'src/app/modules/playlist/playlist.service';
import { loadPlaylist, loadPlaylistList, loadPlaylistListSuccess, loadPlaylistSuccess } from './playlist.action';
import { catchError, map, mergeMap, of } from 'rxjs';
import { MessageService } from 'primeng/api';

@Injectable()
export class PlaylistEffects {
  constructor(
    private actions$: Actions,
    private playlistService: PlaylistService,
    private messageService: MessageService,
  ) {}

  loadPlaylist$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadPlaylist),
      mergeMap(e =>
        this.playlistService.getList(e).pipe(
          map(data => loadPlaylistSuccess({ data })),
          catchError(error => {
            const errorMessage = error.statusText ?? error.message ?? 'Somethink error';
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: errorMessage,
            });
            return of(/* handle error */);
          }),
        ),
      ),
    );
  });

  loadPlaylistList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadPlaylistList),
      mergeMap(e =>
        this.playlistService.getList(e).pipe(
          map(data => loadPlaylistListSuccess({ data })),
          catchError(error => {
            const errorMessage = error.statusText ?? error.message ?? 'Somethink error';
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: errorMessage,
            });
            return of(/* handle error */);
          }),
        ),
      ),
    );
  });
}
