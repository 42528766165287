import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { DividerModule } from 'primeng/divider';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CoreModule } from '../core/core.module';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { DragDropModule } from 'primeng/dragdrop';
import { MultiSelectModule } from 'primeng/multiselect';
import { NgxEditorModule } from 'ngx-editor';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputNumberModule } from 'primeng/inputnumber';
import { CalendarModule } from 'primeng/calendar';
import { HttpClientModule } from '@angular/common/http';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { CdkDropList, CdkDrag } from '@angular/cdk/drag-drop';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { InputMaskModule } from 'primeng/inputmask';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ProgressBarModule } from 'primeng/progressbar';
import { EditorModule } from '@tinymce/tinymce-angular';
import { FileUploadModule } from 'primeng/fileupload';
import { TableModule } from 'primeng/table';
import { QRCodeModule } from 'angularx-qrcode';

@NgModule({
  declarations: [],
  imports: [
    HttpClientModule,
    CommonModule,
    InputTextModule,
    PasswordModule,
    CheckboxModule,
    DividerModule,
    CoreModule,
    DropdownModule,
    InputTextareaModule,
    NgxDropzoneModule,
    DragDropModule,
    NgxEditorModule,
    FormsModule,
    ReactiveFormsModule,
    MultiSelectModule,
    RadioButtonModule,
    InputNumberModule,
    CalendarModule,
    ToastModule,
    CdkDropList,
    CdkDrag,
    ProgressSpinnerModule,
    InputMaskModule,
    SplitButtonModule,
    ProgressBarModule,
    EditorModule,
    FileUploadModule,
    TableModule,
    QRCodeModule,
  ],
  exports: [
    CommonModule,
    InputTextModule,
    PasswordModule,
    CheckboxModule,
    DividerModule,
    CoreModule,
    DropdownModule,
    InputTextareaModule,
    NgxDropzoneModule,
    DragDropModule,
    NgxEditorModule,
    FormsModule,
    ReactiveFormsModule,
    MultiSelectModule,
    RadioButtonModule,
    InputNumberModule,
    CalendarModule,
    HttpClientModule,
    ToastModule,
    CdkDropList,
    CdkDrag,
    ProgressSpinnerModule,
    InputMaskModule,
    SplitButtonModule,
    ProgressBarModule,
    EditorModule,
    FileUploadModule,
    TableModule,
    QRCodeModule,
  ],
  providers: [MessageService],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
