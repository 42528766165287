import { NgModule } from '@angular/core';
import { PlaylistComponent } from './playlist.component';
import { PlaylistRoutingModule } from './playlist-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { PlaylistEffects } from '../../states/playlist/playlist.effect';
import { DatePipe } from '@angular/common';
import { ModalActionPlaylistComponent } from './modal-action-playlist/modal-action-playlist.component';

@NgModule({
  declarations: [PlaylistComponent, ModalActionPlaylistComponent],
  imports: [PlaylistRoutingModule, SharedModule.forRoot(), EffectsModule.forFeature(PlaylistEffects)],
  providers: [DatePipe],
})
export class PlaylistModule {}
