/* eslint-disable @ngrx/no-typed-global-store */
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AuthState } from './states/auth/auth.reducer';
import { selectAuthProperty } from './states/auth/auth.selector';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { loginSuccess, logoutRequest } from './states/auth/auth.action';
import { tap } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'frontend';
  path: any = '';
  modalRelogin = false;

  public auth$: AuthState;

  constructor(private location: Location, private store: Store<{ auth: AuthState }>, private router: Router) {
    const token = localStorage.getItem('token');
    if (token) {
      this.store.dispatch(loginSuccess({ param: { token } }));
    }
  }

  ngOnInit() {
    const authList = ['login', 'reset-password', 'create-new-password'];

    const pathArray = this.location.path().split('/');

    if (pathArray.some(element => element.includes('create-new-password'))) {
      this.path = pathArray[pathArray.length - 2];
    } else {
      this.path = pathArray.pop();
    }

    this.store
      .select(selectAuthProperty)
      .pipe(
        tap(data => {
          this.auth$ = data;
          if ((this.path === '' || !authList.some(element => element.includes(this.path))) && !data.isLogin) {
            this.router.navigate(['/login']);
          }
        }),
      )
      .subscribe();
  }

  logout() {
    localStorage.clear();

    this.store.dispatch(logoutRequest());
    this.router.navigate(['/login']);
  }
}
