import { createReducer, on } from '@ngrx/store';
import { initialState } from './pemateri.model';
import { loadPemateri, loadPemateriSuccess } from './pemateri.action';

export const pemateriReducer = createReducer(
  initialState,
  on(loadPemateri, function (state) {
    return { ...state, status: 'loading', error: null };
  }),
  on(loadPemateriSuccess, function (state, { data }) {
    const result: any = data;
    return { ...state, data: result.data, meta: result.meta, status: 'success' };
  }),
);
