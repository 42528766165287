export interface DataReferalInterface {
  id: string;
  name: string;
  code: string;
  totalScan: number;
  totalUserRegister: number;
  totalUserSubcribeVip: number;
  totalUserSubcribePremium: number;
  totalUserSubcribeExpired: number;
  totalUserSubcribeVipLastMonth: number;
  totalUserSubcribePremiumLast_month: number;
  totalUserSubcribeVipThisMonth: number;
  totalUserSubcribePremiumThisMonth: number;
}

export interface ReferalState {
  data: DataReferalInterface[];
  detail: any;
  error: string | null;
  status: string;
  meta: any;
}

export const initialState: ReferalState = {
  data: [],
  detail: null,
  error: '',
  status: 'pending',
  meta: {
    totalItems: 0,
    itemCount: 0,
    itemsPerPage: 0,
    totalPages: 0,
    currentPage: 0,
  },
};
