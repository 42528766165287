export interface DataPemateriInterface {
  id: string;
  name: string;
}

export interface PemateriState {
  data: DataPemateriInterface[];
  error: string | null;
  status: string;
  meta: any;
}

export const initialState: PemateriState = {
  data: [],
  error: '',
  status: 'pending',
  meta: {
    totalItems: 0,
    itemCount: 0,
    itemsPerPage: 0,
    totalPages: 0,
    currentPage: 0,
  },
};
