import { createAction, props } from '@ngrx/store';

export const loadReferal = createAction('[Referal] Load Referal', props<{ query: any }>());
export const loadReferalSuccess = createAction('[Referal] Load Referal Success', props<{ data: any[] }>());

export const loadListReferal = createAction('[Referal] Load  List Referal', props<{ query: any }>());
export const loadListReferalSuccess = createAction(
  '[Referal] Load List Referal Success',
  props<{ data: any[] }>(),
);
export const loadReferalDetail = createAction(
  '[Referal] Load Referal Detail',
  props<{ id: string; startDate: string; endDate: string }>(),
);
export const loadReferalDetailSuccess = createAction(
  '[Referal] Load Referal Detail Success',
  props<{ data: any }>(),
);
