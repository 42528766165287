import { createAction, props } from '@ngrx/store';

export const loadPlaylist = createAction('[Playlist] Load Playlist', props<{ query: any }>());
export const loadPlaylistSuccess = createAction('[Playlist] Load Playlist Success', props<{ data: any[] }>());

export const loadPlaylistList = createAction('[Playlist] Load Playlist List', props<{ query: any }>());
export const loadPlaylistListSuccess = createAction(
  '[Playlist] Load Playlist List Success',
  props<{ data: any[] }>(),
);

export const loadPlaylistDetail = createAction('[Playlist] Load Playlist Detail', props<{ data: any }>());
export const loadPlaylistDetailSuccess = createAction(
  '[Playlist] Load Playlist Detail Success',
  props<{ data: any }>(),
);
