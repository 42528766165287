import { createAction, props } from '@ngrx/store';

export const loadVideo = createAction('[Video] Load Video', props<{ query: any }>());
export const loadVideoSuccess = createAction('[Video] Load Video Success', props<{ data: any[] }>());

export const loadVideoDetail = createAction('[Video] Load Video Detail', props<{ videoId: string }>());
export const loadVideoDetailSuccess = createAction('[Video] Load Video Detail Success', props<{ data: any }>());

export const loadListBucket = createAction('[Bucket] Load Bucket Detail', props<{ prefix: string }>());
export const loadListBucketSuccess = createAction('[Bucket] Load Bucket Detail Success', props<{ data: any }>());

export const loadListBucketLow = createAction('[Bucket] Load Bucket Low Detail', props<{ prefix: string }>());
export const loadListBucketLowSuccess = createAction(
  '[Bucket] Load Bucket Low Detail Success',
  props<{ data: any }>(),
);

export const loadListBucketStandard = createAction(
  '[Bucket] Load Bucket Standard Detail',
  props<{ prefix: string }>(),
);
export const loadListBucketStandardSuccess = createAction(
  '[Bucket] Load Bucket Standard Detail Success',
  props<{ data: any }>(),
);
