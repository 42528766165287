import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { PlaylistService } from '../playlist.service';
import { loadPlaylist } from '../../../states/playlist/playlist.action';

@Component({
  selector: 'app-modal-action-playlist',
  templateUrl: './modal-action-playlist.component.html',
  styleUrls: ['./modal-action-playlist.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalActionPlaylistComponent implements OnInit {
  @Input() isEdit = null;
  @Input() detail: any = null;
  @Input() modalCreate = false;
  @Input() limit = 10;
  @Input() search = '';
  @Input() page = 1;
  @Output() closeModal = new EventEmitter<void>();

  form: FormGroup;
  loadingModal = false;
  loadingButton = false;
  loadingDelete = false;

  errorForm = {
    title: '',
    titleEnglish: '',
    titleArabic: '',
    description: '',
    descriptionEnglsh: '',
    descriptionArabic: '',
  };

  poster: File;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    private messageService: MessageService,
    private playlistService: PlaylistService,
  ) {
    this.form = this.formBuilder.group({
      title: ['', [Validators.required]],
      titleEnglish: ['', [Validators.required]],
      titleArabic: ['', [Validators.required]],
      description: ['', [Validators.required]],
      descriptionEnglish: ['', [Validators.required]],
      descriptionArabic: ['', [Validators.required]],
    });
  }

  ngOnInit() {
    if (this.isEdit) {
      this.loadingModal = true;

      this.form.patchValue({
        title: this.detail.title,
        titleEnglish: this.detail.titleEnglish,
        titleArabic: this.detail.titleArabic,
        description: this.detail.description,
        descriptionEnglish: this.detail.descriptionEnglish,
        descriptionArabic: this.detail.descriptionArabic,
      });

      this.loadingModal = false;
    }
  }

  closeModalCreate() {
    this.detail = null;
    this.form.patchValue({
      title: '',
      description: '',
    });

    this.modalCreate = false;
    this.closeModal.emit();
  }

  changeField(field) {
    this.errorForm = {
      ...this.errorForm,
      [field]: '',
    };
  }

  async onImageChoose(event: any, type: string) {
    if (type === 'poster') this.poster = event.files[0];
  }

  successAction() {
    this.store.dispatch(
      loadPlaylist({
        query: {
          search: '',
          page: 1,
          limit: this.limit,
        },
      }),
    );

    this.modalCreate = false;
    this.loadingButton = false;
    this.loadingDelete = false;

    this.messageService.add({
      severity: 'success',
      summary: 'Berhasil',
      detail: 'Berhasil menambah data',
    });
  }

  errorAction(error) {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: error?.error?.message ?? error?.message ?? error,
    });
    this.loadingButton = false;
    this.loadingDelete = false;
  }

  async onSubmit() {
    this.loadingButton = true;
    if (!this.isEdit) await this.create();
    else await this.update();
  }

  async create() {
    if (!this.poster) {
      this.errorAction('File harus diupload');

      return true;
    }

    (await this.playlistService.create(this.form.value, this.poster)).subscribe(
      async () => {
        this.successAction();
      },
      error => {
        this.errorAction(error);
      },
    );
  }

  async update() {
    (await this.playlistService.update(this.detail.id, this.form.value, this.poster)).subscribe(
      async () => {
        this.successAction();
      },
      error => {
        this.errorAction(error);
      },
    );
  }

  async remove() {
    this.loadingDelete = true;
    (await this.playlistService.delete(this.detail.id)).subscribe(
      async () => {
        this.successAction();
      },
      error => {
        this.errorAction(error);
      },
    );
  }
}
