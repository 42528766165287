import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { NavigationInterface } from './sidebar.interface';
import { Store } from '@ngrx/store';
import { AuthState } from 'src/app/states/auth/auth.reducer';
import { logoutRequest } from 'src/app/states/auth/auth.action';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  path: any = '';
  navigations: NavigationInterface[] = [
    {
      name: 'Playlist Management',
      icon: 'playlist',
      path: '/',
      subPath: [''],
      queryParams: {},
    },
    {
      name: 'Video Management',
      icon: 'video',
      path: '/video-management',
      subPath: ['video-management'],
      queryParams: {},
    },
    {
      name: 'Category Management',
      icon: 'category-icon',
      path: '/category-management',
      subPath: ['category-management'],
      queryParams: {},
    },
    {
      name: 'About Management',
      icon: 'questionnaire',
      path: '/about-management',
      subPath: ['about-management'],
      queryParams: {},
    },
    {
      name: 'Perpustakaan Management',
      icon: 'book',
      path: '/perpustakaan-management',
      subPath: ['perpustakaan-management'],
      queryParams: {},
    },
    {
      name: 'Referal Management',
      icon: 'book',
      path: '/referal-management',
      subPath: ['referal-management'],
      queryParams: {},
    },
  ];

  constructor(private location: Location, private store: Store<{ auth: AuthState }>, private router: Router) {}

  ngOnInit() {
    const pathArray = this.location.path().split('/');
    const path = pathArray.pop();

    this.path = this.isValidUUID(path) ? pathArray[pathArray.length - 2] : path;
  }

  logout() {
    localStorage.clear();
    this.store.dispatch(logoutRequest());

    this.router.navigate(['/login']);
  }

  isValidUUID(string: string) {
    const pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return pattern.test(string);
  }
}
