<app-modal [showModal]="modalCreate" [title]="isEdit ? 'Edit Playlist' : 'Buat Playlist'" (closeModal)="closeModalCreate()">
  <div *ngIf="loadingModal" class="w-full flex justify-center items-center content-center">
    <h1 class="text-center">Loading...</h1>
  </div>
  <form *ngIf="!loadingModal" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="p-5">
    <div class="grid grid-cols-2 gap-5 gap-y-5">
      <div class="grid grid-cols-1">
        <label class="text-base font-[500] text-black mb-2" htmlFor="title">Judul</label>
        <input
          class="form-create"
          pInputText
          id="title"
          formControlName="title"
          placeholder="Judul"
          aria-describedby="title-help"
          (keypress)="changeField('title')"
          [ngClass]="errorForm.title !== '' ? 'ng-invalid ng-dirty': ''"
        />
        <div *ngIf="errorForm.title !== ''" class="mt-2">
          <p class="text-red-500 text-xs">{{ errorForm.title }}</p>
        </div>
      </div>
      <div class="grid grid-cols-1">
        <label class="text-base font-[500] text-black mb-2" htmlFor="title">Title English</label>
        <input
          class="form-create"
          pInputText
          id="titleEnglish"
          formControlName="titleEnglish"
          placeholder="Judul"
          aria-describedby="titleEnglish-help"
          (keypress)="changeField('titleEnglish')"
          [ngClass]="errorForm.titleEnglish !== '' ? 'ng-invalid ng-dirty': ''"
        />
        <div *ngIf="errorForm.titleEnglish !== ''" class="mt-2">
          <p class="text-red-500 text-xs">{{ errorForm.title }}</p>
        </div>
      </div>
      <div class="grid grid-cols-1">
        <label class="text-base font-[500] text-black mb-2" htmlFor="title">Judul Arabic</label>
        <input
          class="form-create"
          pInputText
          id="titleArabic"
          formControlName="titleArabic"
          placeholder="Judul"
          aria-describedby="titleArabic-help"
          (keypress)="changeField('titleArabic')"
          [ngClass]="errorForm.titleArabic !== '' ? 'ng-invalid ng-dirty': ''"
        />
        <div *ngIf="errorForm.titleArabic !== ''" class="mt-2">
          <p class="text-red-500 text-xs">{{ errorForm.title }}</p>
        </div>
      </div>
      <div class="col-span-2">
        <div class="grid grid-cols-1">
          <label class="text-base font-[500] text-black mb-2" htmlFor="description">Deskripsi Playlist</label>
          <textarea
              placeholder="Tuliskan deskripsi video disini"
              formControlName="description" 
              rows="4" 
              cols="50"
              rows="5"
              class="w-full"
              pInputTextarea
            ></textarea>
        </div>
      </div>
      <div class="col-span-2">
        <div class="grid grid-cols-1">
          <label class="text-base font-[500] text-black mb-2" htmlFor="descriptionEnglish">Deskripsi English</label>
          <textarea
              placeholder="Tuliskan deskripsi video disini"
              formControlName="descriptionEnglish" 
              rows="4" 
              cols="50"
              rows="5"
              class="w-full"
              pInputTextarea
            ></textarea>
        </div>
      </div>
      <div class="col-span-2">
        <div class="grid grid-cols-1">
          <label class="text-base font-[500] text-black mb-2" htmlFor="descriptionArabic">Deskripsi Arabic</label>
          <textarea
              placeholder="Tuliskan deskripsi video disini"
              formControlName="descriptionArabic" 
              rows="4" 
              cols="50"
              rows="5"
              class="w-full"
              pInputTextarea
            ></textarea>
        </div>
      </div>
      <div class="col-span-2">
        <strong>Poster</strong>
        <p-fileUpload name="file"[multiple]="false" accept="image/*"  (onSelect)="onImageChoose($event, 'poster')"  [maxFileSize]="1000000" [showUploadButton]="false" [showCancelButton]="false">
          <ng-template pTemplate="file">
            <div class="flex w-full justify-center items-center content-center">
              <img [src]="poster['objectURL'].changingThisBreaksApplicationSecurity"  class="h-[200px] text-center"/>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
              <ul *ngIf="detail?.poster && !poster">
                <li>
                  <div class="flex w-full justify-center items-center content-center">
                   <img [src]="'https://storage.googleapis.com/alwafa_tarim/' + detail.poster.path"  class="h-[200px] text-center"/>
                  </div>
                 </li>
            </ul>
          </ng-template>
      </p-fileUpload>
      </div>
    </div>
    <div class="w-full flex justify-start gap-5 mt-10">
      <button type="submit" [disabled]="loadingButton" [ngClass]="loadingButton ? 'bg-gray-500': 'bg-[#2A97AD]'" class="p-2 px-4 rounded-md text-white">
        <p class="text-base font-normal">{{ loadingButton ? 'Loading...': 'Simpan' }}</p>
      </button>
      <button type="button" (click)="remove()" *ngIf="isEdit" [disabled]="loadingDelete" [ngClass]="loadingDelete ? 'bg-gray-500': 'bg-red-500'" class="p-2 px-4 rounded-md text-white">
        <p class="text-base font-normal">{{ loadingDelete ? 'Loading...': 'Hapus' }}</p>
      </button>
    </div>
  </div>
</form>
</app-modal>