import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { DatePipe } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { AboutEffects } from '../../states/about/about.effect';
import { AboutComponent } from './about.component';
import { AboutRoutingModule } from './about-routing.module';
import { ModalActionAboutComponent } from './modal-action-about/modal-action-about.component';

@NgModule({
  declarations: [AboutComponent, ModalActionAboutComponent],
  imports: [
    AboutRoutingModule,
    SharedModule.forRoot(),
    SharedModule.forRoot(),
    EffectsModule.forFeature(AboutEffects),
  ],
  providers: [DatePipe],
})
export class AboutModule {}
