<app-base>
  <p-toast></p-toast>
  <div class="w-full relative overflow-x-hidden">
    <div class="flex w-full p-3 pb-0">
      <div class="w-auto items-end relative">
        <p class="font-semibold mt-3">Category Management</p>
        <div class="green-linier w-full h-3 absolute mt-3 rounded-t-md"></div>
      </div>
      <div class="flex flex-1 justify-end">
        <button (click)="togleModal('create')" class="bg-[#2A97AD] p-2 px-4 rounded-md text-white">
          <p class="text-base font-normal">Tambah Kategori</p>
        </button>
      </div>
    </div>
    <div class="relative -ml-5 w-[101%]">
      <p-divider></p-divider>
    </div>
    <div class="p-3 -ml-5">
      <div class="border rounded-md">
        <div class="mt-5 flex px-5">
          <div>
            <span class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <input [(ngModel)]="search" (input)="onInput($event)" class="input-search" placeholder="Search" type="text" pInputText />
            </span>
          </div>
          <div class="w-full flex justify-end pr-5 items-center gap-5">
            <div>
              <p class="font-bold-500 text-sm text-black">Viewing</p>
            </div>
            <div>
              <p-dropdown (onChange)="changeLimit($event)"  class="count" [options]="[5, 15, 20]"></p-dropdown>
            </div>
            <div>
              <p class="font-bold-500 text-sm text-black">of {{ totalRecords }} results</p>
            </div>
          </div>
        </div>
        <div class="relative">
          <p-divider></p-divider>
        </div>
        <app-table [headers]="headers" [loading]="loadingTable" (pageChange)="pageChange($event)" [totalRecords]="totalRecords" [first]="page * limit -limit" [rows]="limit">         
          <tr *ngFor="let category of categorys">
            <td style="text-align: left; padding-left: 30px;">{{ category.type }}</td>
            <td style="text-align: center; padding-left: 30px;">{{ category.name }}</td>
            <td style="text-align: center;">{{ category.english }}</td>
            <td style="text-align: center;">{{ category.arabic }}</td>
            <td style="text-align: center;">{{ category.createdAt | date:'short' }}</td>
            <td style="min-width: 50px!important;">
              <button (click)="togleModal('edit', category)" class="mr-5">
                <img src="/assets/images/icons/pencile.svg" alt="hazlo" />
              </button>
            </td>
          </tr>
        </app-table>
      </div>
    </div>
  </div>

  <ng-container     
    *ngIf="modalCreate">
    <app-modal-action-category
    [limit]="limit"
    [search]="search"
    [page]="page"
    [modalCreate]="modalCreate"
    [detail]="detail"
    [isEdit]="isEdit"
    (closeModal)="closeModalCreate()"
  ></app-modal-action-category>
 </ng-container>
</app-base>
